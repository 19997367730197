import React, { Component } from 'react';
import config from "./../config.json";

class PersonelCard extends Component {

  state = {
    values: this.props.values,
  }

  onClick = (event) => {
    this.props.parentCallback(this.state.values);
    event.preventDefault();
  }

  contextMenu= (e) => {
    e.preventDefault();
  }

  render() {
    if (this.state.values.kullanici.resim == null) {
      this.state.values.kullanici.resim = config.blankPhoto
    }
    return <div className="col">
      <div className="card h-100">
        <img className="card-img-top" loading="lazy" data-bs-toggle="modal" type="button" onClick={this.onClick} data-bs-target="#modalCenter" src={config.imageEndpoint + this.state.values.kullanici['resim']} onContextMenu={this.contextMenu}/>
        <div className="card-body">
          <h5 className="card-title">{this.state.values.kullanici['ad']} {this.state.values.kullanici['soyad']}</h5>
          <p className="card-text">
            {this.state.values.kullanici['departmanAdi']} - {this.state.values.kullanici['gorevi']}
          </p>
        </div>
        <div className="modal-footer"><button onClick={this.onClick}
          type="button"
          className="btn btn-outline-primary "
          data-bs-toggle="modal"
          data-bs-target="#modalCenter">Detay
        </button></div>
      </div>
    </div>;
  }

}

export default PersonelCard;