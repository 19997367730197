import React, { Component } from 'react';
import config from "./../config.json";

class PersonelListCard extends Component {

  state = {
    values: this.props.values,
    isAllFields: this.props.isAllFields
  }

  onClick = (event) => {
    this.props.parentCallback(this.state.values);
    event.preventDefault();
  }

  contextMenu = (e) => {
    e.preventDefault();
  }

  render() {
    if (this.state.values.kullanici.resim == null) {
      this.state.values.kullanici.resim = config.blankPhoto
    }
    return <tr>
      <td><img className="card-img-top card-img-bottom" src={config.imageEndpoint + this.state.values.kullanici['resim']} onContextMenu={this.contextMenu} /></td>
      <td>{this.state.values.kullanici['ad']} {this.state.values.kullanici['soyad']}</td>
      {this.state.isAllFields ?
        <React.Fragment>
          <td>{this.state.values.kullanici['tcKimlikNo']}</td>
          <td>{this.state.values.kullanici['telefon']}</td>
          <td>{this.state.values.kullanici['dogumTarihi'] != null ? new Date(this.state.values.kullanici['dogumTarihi']).toLocaleDateString("tr-TR") : null}</td>
          <td>{this.state.values.kullanici['dogumYeri']}</td>
          <td>{this.state.values.kullanici['il']}</td>
          <td>{this.state.values.kullanici['ilce']}</td>
          <td>{this.state.values.kullanici['iseBaslamaTarihi'] != null ? new Date(this.state.values.kullanici['iseBaslamaTarihi']).toLocaleDateString("tr-TR") : null}</td>
          <td>{this.state.values.kullanici['istenAyrilmaTarihi'] != null ? new Date(this.state.values.kullanici['istenAyrilmaTarihi']).toLocaleDateString("tr-TR") : null}</td>
        </React.Fragment>
        : null}
      <td>{this.state.values.kullanici['gorevi']}</td>
      <td>{this.state.values.kullanici['departmanAdi']}</td>
      <td><button onClick={this.onClick}
        type="button"
        className="btn btn-outline-primary "
        data-bs-toggle="modal"
        data-bs-target="#modalCenter">Detay
      </button></td>
    </tr>;
  }

}

export default PersonelListCard;