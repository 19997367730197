import React, { Component } from 'react';

class IslemlerCard extends Component {

  state = {
    values: this.props.values,
    rolIslemleri : this.props.rolIslemleri,
  }

  handlerolIslemChanged = (event) => {
    this.props.handleIslemChangeCallback(event.target);
  }


  render() {

    console.log(this.state.rolIslemleri)

    return <div className="row gy-3">
      <div className="col-md">
        <small className="text-light fw-semibold d-block">{this.state.values['islemGrubu']}</small>
        {this.state.values['grupIslemler'].map(tag => <div className="form-check form-check-inline mt-3" key={tag['islemId']}>
          <input className="form-check-input" type="checkbox" id={tag['islemId']} value={tag['islemId']} onChange={this.handlerolIslemChanged} checked={this.state.rolIslemleri !== null && this.state.rolIslemleri.filter((islem) => islem === tag['islemId']).length > 0 ? 'checked' : null}/>
          <label className="form-check-label" htmlFor={tag['islemId']}>{tag['aciklama']}</label>
        </div>)}
      </div>
    </div>;
  }
}

export default IslemlerCard;