import React, { Component } from 'react';
import config from "./../config.json";

class RightMenu extends Component {

  state = {
    userHasDepatmanPermission: false,
    userHasRolPermission: false,
  }

  componentDidMount() {
    this.setValues();
  }

  componentWillUnmount() {
  }

  async setValues() {
    var userHasDepatmanPermission = JSON.parse(localStorage.getItem('user'))['claims'].filter((claim) => claim.Name === 'DepartmanGuncelleme').length > 0;
    this.setState({ userHasDepatmanPermission });

    var userHasRolPermission = JSON.parse(localStorage.getItem('user'))['claims'].filter((claim) => claim.Name === 'RolGuncelleme').length > 0;
    this.setState({ userHasRolPermission });
  }

  render() {

    return localStorage.getItem('user') == null ? '' :
      <div className="container-xxl">
        <a href="/Album"><h1 className="logo">Altınkaynak</h1></a>
        <ul className="navbar-nav flex-row align-items-center ms-auto">

          <li className="nav-item navbar-dropdown dropdown-user dropdown">
            <a className="nav-link dropdown-toggle hide-arrow" href="#" data-bs-toggle="dropdown">
              <div className="avatar avatar-online" style={{ backgroundImage: 'url(' + (localStorage.getItem('user') == null ? config.blankPhoto : config.imageEndpoint + JSON.parse(localStorage.getItem('user'))["photo"]) + ')' }}>
              </div>
            </a>
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <a className="dropdown-item" href="#">
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <div className="avatar avatar-online" style={{ backgroundImage: 'url(' + (localStorage.getItem('user') == null ? config.blankPhoto : config.imageEndpoint + JSON.parse(localStorage.getItem('user'))["photo"]) + ')' }}>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <span className="fw-semibold d-block">{ JSON.parse(localStorage.getItem('user'))["username"]}</span>
                      <small className="text-muted">{JSON.parse(localStorage.getItem('user'))["department"]}</small>
                      <small className="text-muted">{JSON.parse(localStorage.getItem('user'))["job"]}</small>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <div className="dropdown-divider"></div>
              </li>
              {(this.state.userHasDepatmanPermission || this.state.userHasRolPermission) ?
                <React.Fragment>
                  <li>
                    <a className="dropdown-item" href="/Album">
                      <i className="bx bx-user-circle me-2"></i>
                      <span className="align-middle">Albüm</span>
                    </a>
                  </li>
                  {this.state.userHasRolPermission ? <li>
                    <a className="dropdown-item" href="/Kullanicilar">
                      <i className="bx bx-user"></i>
                      <span className="align-middle">Kullanıcılar</span>
                    </a>
                  </li>: null}
                  {this.state.userHasDepatmanPermission ? <li>
                    <a className="dropdown-item" href="/Departman">
                      <i className="bx bx-buildings me-2"></i>
                      <span className="align-middle">Departmanlar</span>
                    </a>
                  </li> : null}
                  {this.state.userHasRolPermission ? <li>
                    <a className="dropdown-item" href="/Rol">
                      <i className="bx bx-key me-2"></i>
                      <span className="align-middle">Roller</span>
                    </a>
                  </li>: null}
                  <li>
                    <div className="dropdown-divider"></div>
                  </li>
                </React.Fragment> : null}
              <li>
                <a className="dropdown-item" href="/">
                  <i className="bx bx-power-off me-2"></i>
                  <span className="align-middle">Çıkış</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>;

  }
}

export default RightMenu;