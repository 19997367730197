import React, { Component } from 'react';
import config from "./../config.json";
import RolCard from '../components/rolCard';
import IslemlerCard from '../components/islemlerCard';
import ToastCard from '../components/toast';
import RightMenu from '../components/rightMenu'
import _ from "lodash";


class RolSayfa extends Component {
  state = {
    roller: [],
    personeller :  [],
    showDetail: false,
    rolDetail: {},
    response: {},
    showToast: false,
    datetime: Date.now(),
  }

  headers = {
    Authorization : 'Bearer ' +  (localStorage.getItem('user')  == null ? '' : JSON.parse(localStorage.getItem('user'))["access_token"]),
    'Content-Type': 'application/json',
  }

  componentDidMount() {
    this.setValues();
    document.title = "Altınkaynak Personel Sistemi - Roller"
  }

  componentWillUnmount() {
  }

  async setValues() {
    var roller = [];
    await fetch(config.apiEndpoint + 'rol', {method: 'GET', headers:this.headers}).then(function(response) {
      return response.json();
    })
    .then(function(jsonStr) {
      roller = jsonStr;
    });

    var islemler = [];
    await fetch(config.apiEndpoint + 'islem', {method: 'GET', headers:this.headers}).then(function(response) {
      return response.json();
    })
    .then(function(jsonStr) {
      islemler = jsonStr;
    });

    var gruppedIslemler =  _(islemler).groupBy('islemGrubu').map((islemler, islemGrubu) => {
      const grupIslemler = islemler.map(islem => islem);
      return ({
        islemGrubu,
        grupIslemler,
      })
    })
    .value()

    var personeller = [];
    await fetch(config.apiEndpoint + 'kullanici/Search', {method: 'POST', body:JSON.stringify({}), headers:this.headers}).then(function(response) {
      return response.json();
    })
    .then(function(jsonStr) {
      personeller = jsonStr;
    });

    this.setState({ roller, islemler : gruppedIslemler, personeller })
  }


  handleOpenModal = (rolDetail) => {
    this.setState({ showDetail: true, rolDetail: rolDetail });
  }

  handleCloseModal = (event) => {
    this.setState({ showDetail: false });
  }

  handleAddRol = (event) => {
    this.setState({ showDetail: true, rolDetail: {'islemler':[]} });
  }

  handleIslemChange =  (target) => {
    var newRolDetail = this.state.rolDetail;
    if(target.checked){
      newRolDetail['islemler'].push(parseInt(target.value))
      this.setState({ rolDetail: newRolDetail });
    }
    else{
      newRolDetail['islemler'].splice(newRolDetail['islemler'].indexOf(parseInt(target.value)),1)
      this.setState({ rolDetail: newRolDetail });
    }
    
  }

  handleSaveForm = async (event) => {
    event.preventDefault();

    const rolDetailChanged = Object.assign({}, this.state.rolDetail);

    rolDetailChanged.adi = event.target['adi'].value;

    var mesaj = ''
    if (rolDetailChanged.rolId == null) {
      await fetch(config.apiEndpoint + 'rol/', {method: 'POST', body:JSON.stringify(rolDetailChanged), headers:this.headers}).then(function(response) {
        return response.json();
      })
      .then(function(jsonStr) {
        mesaj = jsonStr;
      });
    }
    else {
      this.setState({ rolDetail: rolDetailChanged });
      await fetch(config.apiEndpoint + 'rol/' + this.state.rolDetail.rolId, {method: 'PUT', body:JSON.stringify(rolDetailChanged), headers:this.headers}).then(function(response) {
        return response.json();
      })
      .then(function(jsonStr) {
        mesaj = jsonStr;
      });
    }
    this.setState({ response: mesaj, showToast: true, datetime: Date.now(), });
    this.setValues();
  }

  handleDeleteRol = async (rolDetail) => {
    var mesaj = '';
    await fetch(config.apiEndpoint + 'rol/' + rolDetail.rolId, {method: 'DELETE', headers:this.headers}).then(function(response) {
      return response.json();
    })
    .then(function(jsonStr) {
      mesaj = jsonStr;
    });
    this.setState({ response: mesaj, showToast: true, datetime: Date.now(), });
    this.setValues();
  }

  render() {
    return <React.Fragment>
      <RightMenu />

      <ToastCard response={this.state.response} showToast={this.state.showToast} datetime={this.state.datetime} />
      <div className="modal fade" id="modalCenter" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            {this.state.showDetail ?
              <form id="formAuthenticationc" className="mb-3" onSubmit={this.handleSaveForm}>
                <div className="modal-header">
                  <h5 className="modal-title" id="modalCenterTitle">{this.state.rolDetail['adi']}</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="row g-2">
                    <div className="col mb-0 form-floating">
                      <input
                        type="text"
                        id="adi"
                        name="adi"
                        className="form-control"
                        placeholder="Adı"
                        key={this.state.rolDetail['adi']}
                        defaultValue={this.state.rolDetail['adi']}
                      />
                      <label htmlFor="adi" className="form-label">Adı</label>
                    </div>
                  </div>

                  {this.state.islemler.map(tag => <IslemlerCard key={this.state.rolDetail['adi'] + tag['islemGrubu'] + (this.state.roller.filter((rol) => rol.rolId === this.state.rolDetail['rolId']).length > 0 ? this.state.roller.filter((rol) => rol.rolId === this.state.rolDetail['rolId'])[0].islemler.toString() : 'New')} values={tag} handleIslemChangeCallback={this.handleIslemChange} rolIslemleri={this.state.roller.filter((rol) => rol.rolId === this.state.rolDetail['rolId']).length > 0 ? this.state.roller.filter((rol) => rol.rolId === this.state.rolDetail['rolId'])[0].islemler : null} />)}

                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">Kaydet</button>
                </div>
              </form> : null}
          </div>
        </div>
      </div>

      <div className="content-wrapper">

        <div className="container-xxl flex-grow-1 container-p-y">
          <h4 className="fw-bold py-2 mb-1">Roller
            <button type="button" className="btn btn-outline-primary thin ml20" data-bs-toggle="modal"
              data-bs-target="#modalCenter" onClick={this.handleAddRol}>
              <i className="tf-icons bx bx-building"></i>
              Rol Ekle
            </button>
          </h4>
          
          <div className="row row-cols-1 row-cols-md-3 g-4 mb-5">
            <table className="table">
              <thead>
                <tr>
                  <th>Adı</th>
                </tr>
              </thead>
              <tbody className="table-border-bottom-0">
                {this.state.roller.map(tag => <RolCard key={tag['adi']} parentCallback={this.handleOpenModal} parentDeleteCallback={this.handleDeleteRol} values={tag} users={this.state.personeller} />)}
              </tbody>
            </table>
          </div>

        </div>

        <footer className="content-footer footer bg-footer-theme">
          <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
            <div className="mb-2 mb-md-0">
              © Altınkaynak
            </div>
          </div>
        </footer>

        <div className="content-backdrop fade"></div>
      </div>
      <div className="layout-overlay layout-menu-toggle"></div>

    </React.Fragment>;
  }
}

export default RolSayfa;