import React, { Component } from 'react';
import config from "./../config.json";

class DepartmanCard extends Component {

  state = {
    values: this.props.values,
  }

  onClick = (event) => {
    this.props.parentCallback(this.state.values);
    event.preventDefault();
  }

  onDeleteClick= (event) => {
    this.props.parentDeleteCallback(this.state.values);
    event.preventDefault();
  }

  render() {

    return <tr>
      <td>{this.state.values['adi']}</td>
      <td>
        <div className="dropdown">
          <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
            <i className="bx bx-dots-vertical-rounded"></i>
          </button>
          <div className="dropdown-menu">
            <button className="dropdown-item" onClick={this.onClick} data-bs-toggle="modal"
              data-bs-target="#modalCenter">
              <i className="bx bx-edit-alt me-1"></i> Güncelle</button>
            <button className="dropdown-item" onClick={this.onDeleteClick}>
              <i className="bx bx-trash me-1"></i> Sil</button>
          </div>
        </div>
      </td>
    </tr>;
  }
}

export default DepartmanCard;