import React, { Component } from 'react';
import config from "./../config.json";
import DepartmanCard from '../components/departmanCard';
import ToastCard from '../components/toast';
import RightMenu from '../components/rightMenu'


class DepartmanSayfa extends Component {
  state = {
    departmanlar: [],
    showDetail: false,
    departmanDetail: {},
    response: {},
    showToast: false,
    datetime: Date.now(),
  }

  headers = {
    Authorization : 'Bearer ' +  (localStorage.getItem('user')  == null ? '' : JSON.parse(localStorage.getItem('user'))["access_token"]),
    'Content-Type': 'application/json',
  }

  componentDidMount() {
    this.setValues();
    document.title = "Altınkaynak Personel Sistemi - Departmanlar"
  }

  componentWillUnmount() {
  }

  async setValues() {
    var departmanlar = [];
    await fetch(config.apiEndpoint + 'departman', {method: 'GET', headers:this.headers}).then(function(response) {
      return response.json();
    })
    .then(function(jsonStr) {
      departmanlar = jsonStr;
    });

    this.setState({ departmanlar })

  }


  handleOpenModal = (departmanDetail) => {
    this.setState({ showDetail: true, departmanDetail: departmanDetail });
  }

  handleCloseModal = (event) => {
    this.setState({ showDetail: false });
  }

  handleAddDepartment = (event) => {
    this.setState({ showDetail: true, departmanDetail: {} });
  }

  handleSaveForm = async (event) => {
    event.preventDefault();

    const departmanDetailChanged = Object.assign({}, this.state.departmanDetail);

    departmanDetailChanged.adi = event.target['adi'].value;

    var mesaj = '';
    if (departmanDetailChanged.departmanId == null) {
      await fetch(config.apiEndpoint + 'departman/', {method: 'POST', body:JSON.stringify(departmanDetailChanged), headers:this.headers}).then(function(response) {
        return response.json();
      })
      .then(function(jsonStr) {
        mesaj = jsonStr;
      });
    }
    else {
      this.setState({ departmanDetail: departmanDetailChanged });
      await fetch(config.apiEndpoint + 'departman/' + this.state.departmanDetail.departmanId, {method: 'PUT', body:JSON.stringify(departmanDetailChanged), headers:this.headers}).then(function(response) {
        return response.json();
      })
      .then(function(jsonStr) {
        mesaj = jsonStr;
      });
    }
    this.setState({ response: mesaj, showToast: true, datetime: Date.now(), });
    this.setValues();
  }

  handleDeleteDepartment = async (departmanDetail) => {
    var mesaj = '';
    await fetch(config.apiEndpoint + 'departman/' + departmanDetail.departmanId, {method: 'DELETE', headers:this.headers}).then(function(response) {
      return response.json();
    })
    .then(function(jsonStr) {
      mesaj = jsonStr;
    });
    this.setState({ response: mesaj, showToast: true, datetime: Date.now(), });
    this.setValues();
  }

  render() {
    return <React.Fragment>
      <RightMenu />

      <ToastCard response={this.state.response} showToast={this.state.showToast} datetime={this.state.datetime} />
      <div className="modal fade" id="modalCenter" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            {this.state.showDetail ?
              <form id="formAuthenticationc" className="mb-3" onSubmit={this.handleSaveForm}>
                <div className="modal-header">
                  <h5 className="modal-title" id="modalCenterTitle">{this.state.departmanDetail['adi']}</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="row g-2">
                    <div className="col mb-0 form-floating">
                      <input
                        type="text"
                        id="adi"
                        name="adi"
                        className="form-control"
                        placeholder="Adı"
                        key={this.state.departmanDetail['adi']}
                        defaultValue={this.state.departmanDetail['adi']}
                      />
                      <label htmlFor="adi" className="form-label">Adı</label>
                    </div>

                  </div>

                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">Kaydet</button>
                </div>
              </form> : null}
          </div>
        </div>
      </div>

      <div className="content-wrapper">

        <div className="container-xxl flex-grow-1 container-p-y">
          <h4 className="fw-bold py-2 mb-1">Departmanlar
            <button type="button" className="btn btn-outline-primary thin ml20" data-bs-toggle="modal"
              data-bs-target="#modalCenter" onClick={this.handleAddDepartment}>
              <i className="tf-icons bx bx-building"></i>
              Departman Ekle
            </button>
          </h4>
          
          <div className="row row-cols-1 row-cols-md-3 g-4 mb-5">
            <table className="table">
              <thead>
                <tr>
                  <th>Adı</th>
                </tr>
              </thead>
              <tbody className="table-border-bottom-0">
                {this.state.departmanlar.map(tag => <DepartmanCard key={tag['adi']} parentCallback={this.handleOpenModal} parentDeleteCallback={this.handleDeleteDepartment} values={tag} />)}
              </tbody>
            </table>
          </div>

        </div>

        <footer className="content-footer footer bg-footer-theme">
          <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
            <div className="mb-2 mb-md-0">
              © Altınkaynak
            </div>
          </div>
        </footer>

        <div className="content-backdrop fade"></div>
      </div>
      <div className="layout-overlay layout-menu-toggle"></div>

    </React.Fragment>;
  }
}

export default DepartmanSayfa;