import React, { Component } from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import './App.css';

import GirisSayfa from './pages/giris';
import AlbumSayfa from './pages/album';
import DepartmanSayfa from './pages/departman';
import RolSayfa from './pages/rol';


class App extends Component {
  render() {
    const loggedin = (localStorage.getItem('user')  !== null  &&  JSON.parse(localStorage.getItem('user'))["expired_at"] > (new Date().getTime() / 1000))

    return <React.Fragment>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">

          <div className="layout-page">
            <Routes >
              <Route path="/" element={<GirisSayfa />} />
              <Route path="/Album" element={loggedin ? <AlbumSayfa /> : <GirisSayfa />} />
              <Route path="/Kullanicilar" element={loggedin ? <AlbumSayfa /> : <GirisSayfa />} />
              <Route path="/Departman" element={loggedin ?<DepartmanSayfa />: <GirisSayfa />} />
              <Route path="/Rol" element={loggedin ?<RolSayfa />: <GirisSayfa />} />
            </Routes >
          </div>
        </div>
      </div>
    </React.Fragment>
  }
}

export default App;

