import React, { Component } from 'react';
import config from "./../config.json";

class RolCard extends Component {

  state = {
    values: this.props.values,
    users: this.props.users,
  }

  onClick = (event) => {
    this.props.parentCallback(this.state.values);
    event.preventDefault();
  }

  onDeleteClick = (event) => {
    this.props.parentDeleteCallback(this.state.values);
    event.preventDefault();
  }

  render() {

    let filteredKullanici = this.state.users.kullanicilar.filter((personel) => personel.kullanici.aktifMi === true && personel.kullaniciRolleri.filter((rol) => rol.rolId === this.state.values['rolId']).length > 0)

    return <tr>
      <td>{this.state.values['adi']}</td>
      <td>
        <ul className="list-unstyled users-list m-0 avatar-group d-flex align-items-center">
          {filteredKullanici.map(tag => <li key={tag.kullanici["kullaniciId"]}
            data-bs-toggle="tooltip"
            data-popup="tooltip-custom"
            data-bs-placement="top"
            className="avatar avatar-xs pull-up"
            title={tag.kullanici["ad"] + ' ' + tag.kullanici["soyad"]}>
            <img loading="lazy" src={config.imageEndpoint + tag.kullanici["resim"]} alt="Avatar" className="rounded-circle" />
          </li>)}
        </ul>
      </td>

      <td>
        <div className="dropdown">
          <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
            <i className="bx bx-dots-vertical-rounded"></i>
          </button>
          <div className="dropdown-menu">
            <button className="dropdown-item" onClick={this.onClick} data-bs-toggle="modal"
              data-bs-target="#modalCenter">
              <i className="bx bx-edit-alt me-1"></i> Güncelle</button>
            <button className="dropdown-item" onClick={this.onDeleteClick}>
              <i className="bx bx-trash me-1"></i> Sil</button>
          </div>
        </div>
      </td>
    </tr>;
  }
}

export default RolCard;