import React, { Component } from 'react';

class ToastCard extends Component {

    state = {
        response: this.props.response,
        showToast: this.props.showToast,
        datetime: this.props.datetime,
    }

    componentDidUpdate(prevProps) {

        if (this.props.datetime !== prevProps.datetime) {
            this.setState({ response: this.props.response, showToast :this.props.showToast });
        }
    }

    handleClose = (event) => {
        this.setState({ showToast: false });
    }

    render() {

        return <div className={'bs-toast toast toast-placement-ex m-2 fade bg-' + (this.state.response.messageType === 1 ? 'success' : 'danger') + ' top-0 end-0 ' + (this.state.showToast ? 'show' : 'hide')} role="alert" aria-live="assertive" aria-atomic="true" data-delay="2000">
            <div className="toast-header">
                <i className="bx bx-bell me-2"></i>
                <div className="me-auto fw-semibold">{this.state.response.messageType === 1 ? 'Başarılı' : 'Hata'}</div>
                <button type="button" className="btn-close" onClick={this.handleClose} aria-label="Close"></button>
            </div>
            <div className="toast-body">
                {this.state.response.message}
            </div>
        </div>;
    }

}

export default ToastCard;



