import React, { Component, Fragment } from 'react';
import config from "./../config.json";
import PersonelCard from '../components/personelCard';
import PersonelListCard from '../components/personelListCard';
import ToastCard from '../components/toast';
import RightMenu from '../components/rightMenu'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import PerfectScrollbar from "react-perfect-scrollbar";
import 'react-perfect-scrollbar/dist/css/styles.css';

var ALBUM_SECINIZ = window.location.pathname !== "/Kullanicilar" ? "Albüm Seçiniz" : "Kullanıcılar";
var KAN_GRUBU = "Kan Grubu";
var AYRILANLAR = "--Ayrılanlar--";
class AlbumSayfa extends Component {
  
  state = {
    personeller: [],
    departmanlar: [],
    roller: [],
    toplam: 0,
    arananPersoneller: [],
    arananToplam: 0,
    showDetail: false,
    formItemDisabled: true,
    personelDetail: {},
    response: {},
    showToast: false,
    datetime: Date.now(),
    userHasEditPermission: false,
    showWorkingPeoples: true,
    kullaniciDepartmant: 0,
    kullanicikanGrubu:'',
    isCard: true,
    showPersonels: window.location.pathname !== "/Kullanicilar",
    departmanText :ALBUM_SECINIZ,
    showWorkingPeoplesButton : false,
    buttonsClass: "",
  }



  headers = {
    Authorization : 'Bearer ' +  (localStorage.getItem('user')  == null ? '' : JSON.parse(localStorage.getItem('user'))["access_token"]),
    'Content-Type': 'application/json',
  }

  componentDidMount() {
    this.setValues();
    document.title = "Altınkaynak Personel Sistemi - Personeller"
  }

  componentWillUnmount() {
  }

  async setValues() {
    var buttonClassName = "mb-45";
    if(window.location.pathname !== "/Kullanicilar"){
      document.getElementById("table-button").style.display = "none";
      buttonClassName = "mb-15";
    }
    this.setState({buttonsClass : buttonClassName });

    var userHasEditPermission = JSON.parse(localStorage.getItem('user'))['claims'].filter((claim) => claim.Name === 'KullaniciGuncelleme').length > 0;
    this.setState({ userHasEditPermission: userHasEditPermission });

    var personeller = [];
    var departmanlar = [];
    var roller = [];
    await fetch(config.apiEndpoint + 'kullanici/Search', {method: 'POST', body:JSON.stringify({}), headers:this.headers}).then(function(response) {
      return response.json();
    })
    .then(function(jsonStr) {
      personeller = jsonStr;
    });

    await fetch(config.apiEndpoint + 'departman', {method: 'GET', headers:this.headers}).then(function(response) {
      return response.json();
    })
    .then(function(jsonStr) {
      departmanlar = jsonStr;
    });

    await fetch(config.apiEndpoint + 'rol', {method: 'GET', headers:this.headers}).then(function(response) {
      return response.json();
    })
    .then(function(jsonStr) {
      roller = jsonStr;
    });

    if (personeller !== null) {
      var arananPersoneller = personeller.kullanicilar.filter((personel) => personel.kullanici.aktifMi === true && personel.kullanici.personelMi === this.state.showPersonels)
      this.setState({ departmanlar, roller, personeller: personeller.kullanicilar, toplam: personeller.toplam, arananPersoneller: (this.state.showPersonels ? [] : arananPersoneller) , arananToplam: 0 })
    }
  }

  handleMainPage = (event) => {
    document.getElementById("selected-album").innerText = ALBUM_SECINIZ;
    document.getElementById("selected-album-text").innerText = ALBUM_SECINIZ
    document.getElementById("selected-kan").innerText = KAN_GRUBU;
    document.getElementById("kvkk").style.display = "block";
    document.getElementById("mainpage-button").classList.add("hidden");

    this.searchPersonel();
  }

  handleSearchDepartmanChange = (event) => {
    event.preventDefault();
    var seciliDepartman = event.target.getAttribute('value');
    document.getElementById("selected-album").innerText = seciliDepartman;

    
    if (seciliDepartman === ALBUM_SECINIZ){
      this.setState({ departmanText : ALBUM_SECINIZ });
    }
    else{
      var deparmanText =  this.state.showPersonels ? seciliDepartman + " Albümü" : "Kullanıcılar";
      this.setState({ departmanText : deparmanText});
    }

    this.checkMainPageButton();
    this.searchPersonel();
  };


  checkMainPageButton() {
    var seciliDepartman = document.getElementById("selected-album").innerText;
    var seciliKan = KAN_GRUBU;
    if(this.state.userHasEditPermission)
      seciliKan = document.getElementById("selected-kan").innerText;

    if (seciliKan === KAN_GRUBU && seciliDepartman === ALBUM_SECINIZ){
      document.getElementById("kvkk").style.display = "block";
      document.getElementById("mainpage-button").classList.add("hidden");
    }
    else{
      document.getElementById("kvkk").style.display = "none";
      document.getElementById("mainpage-button").classList.remove("hidden");
    }
  }


  handleSearchKanChange = (event) => {
    event.preventDefault();
    var seciliKan = event.target.getAttribute('value');
    document.getElementById("selected-kan").innerText = seciliKan;

    this.checkMainPageButton();
    this.searchPersonel();
  };

  searchPersonel = async (refresh = false) => {

    if (refresh) {
      var personeller = []
      await fetch(config.apiEndpoint + 'kullanici/Search', {method: 'POST', body:JSON.stringify({}), headers:this.headers}).then(function(response) {
        return response.json();
      })
      .then(function(jsonStr) {
        personeller = jsonStr;
      });

      this.setState({ personeller: personeller.kullanicilar, toplam: personeller.toplam })
    }

    var seciliDepartman = document.getElementById("selected-album").innerText;
    var seciliKan = KAN_GRUBU;
    if(this.state.userHasEditPermission)
      seciliKan = document.getElementById("selected-kan").innerText;

    var arananPersoneller = this.state.personeller;
    var showWorkingPeoples = this.state.showWorkingPeoples;
    this.setState({showWorkingPeoplesButton : true});
    if (seciliDepartman !== ALBUM_SECINIZ){
      if (seciliDepartman === AYRILANLAR){
        showWorkingPeoples = false;
        arananPersoneller = this.state.personeller;
        this.setState({showWorkingPeoplesButton : false});
      }
      else
        arananPersoneller = this.state.personeller.filter((personel) => personel.kullanici.departmanAdi === seciliDepartman)
    }
    else
      arananPersoneller = []

    if (seciliDepartman === ALBUM_SECINIZ){
      this.setState({showWorkingPeoplesButton : false});
    }

    if (seciliKan !== KAN_GRUBU){
        if (seciliDepartman !== ALBUM_SECINIZ)
          arananPersoneller = arananPersoneller.filter((personel) => personel.kullanici.kanGrubu === seciliKan)
        else
          arananPersoneller = this.state.personeller.filter((personel) => personel.kullanici.kanGrubu === seciliKan)
    }


    var aramaKelimesi = document.getElementById("search-input").value.toLocaleLowerCase('tr-TR');
    if (aramaKelimesi !== ''){
      if (seciliDepartman !== ALBUM_SECINIZ | seciliKan !== KAN_GRUBU)
        arananPersoneller = arananPersoneller.filter((personel) => (personel.kullanici.ad !== null && personel.kullanici.ad.toLocaleLowerCase('tr-TR').indexOf(aramaKelimesi) !== -1) || (personel.kullanici.soyad !== null && personel.kullanici.soyad.toLocaleLowerCase('tr-TR').indexOf(aramaKelimesi) !== -1)|| (personel.kullanici.ad !== null && personel.kullanici.soyad !== null && (personel.kullanici.ad + ' ' +personel.kullanici.soyad).toLocaleLowerCase('tr-TR').indexOf(aramaKelimesi) !== -1))
      else
        arananPersoneller = this.state.personeller.filter((personel) => (personel.kullanici.ad !== null && personel.kullanici.ad.toLocaleLowerCase('tr-TR').indexOf(aramaKelimesi) !== -1) || (personel.kullanici.soyad !== null && personel.kullanici.soyad.toLocaleLowerCase('tr-TR').indexOf(aramaKelimesi) !== -1)|| (personel.kullanici.ad !== null && personel.kullanici.soyad !== null && (personel.kullanici.ad + ' ' +personel.kullanici.soyad).toLocaleLowerCase('tr-TR').indexOf(aramaKelimesi) !== -1))
    }
    arananPersoneller = arananPersoneller.filter((personel) => personel.kullanici.aktifMi === showWorkingPeoples && personel.kullanici.personelMi === this.state.showPersonels)
    this.setState({ arananPersoneller: arananPersoneller, toplam: arananPersoneller.length })

    if(arananPersoneller.length === 0)
      document.getElementById("table-button").style.display = "none";
    else
      document.getElementById("table-button").style.display = "inline-block";
    
  }

  handleOpenMyProfile = (event) => {
    event.preventDefault();
    var myKullaniciId = JSON.parse(localStorage.getItem('user'))["id"]
    var personelDetail = this.state.personeller.filter((personel) => personel.kullanici.kullaniciId === myKullaniciId)[0]
    this.setState({ showDetail: true, personelDetail: personelDetail, formItemDisabled: true });

  }

  handleWorkingType = (event) => {
    this.setState({ showWorkingPeoples: !this.state.showWorkingPeoples },
      function () {
        this.searchPersonel();
      });
  }

  handleDisplayType = (event) => {
    this.setState({ isCard: !this.state.isCard })
    //this.searchPersonel();
  }


  handlekullaniciDepartmantChanged = (event) => {
    this.setState({ kullaniciDepartmant: event.target.value })
  }

  handlekullanicikanGrubuChanged= (event) => {
    this.setState({ kullanicikanGrubu: event.target.value })
  }

  handleOpenModal = (personelDetail) => {
    this.setState({ showDetail: true, personelDetail: personelDetail, formItemDisabled: true, kullaniciDepartmant: personelDetail.kullanici['departmanId'], kullanicikanGrubu: personelDetail.kullanici['kanGrubu'] });
  }

  handleIsRolSelected = (rolId) => {
    var index = this.state.personelDetail.kullaniciRolleri.findIndex((kullaniciRolu) => kullaniciRolu.rolId === rolId)
    return index >= 0;
  }

  handleRolSelected = (e) => {
    const { value, checked } = e.target;
    var personelDetail = this.state.personelDetail;
    var index = personelDetail.kullaniciRolleri.findIndex((kullaniciRolu) => kullaniciRolu.rolId === parseInt(value))
    if (checked === false && index >= 0) {
      personelDetail.kullaniciRolleri.splice(index, 1)
    }
    else if (checked && index < 0) {
      personelDetail.kullaniciRolleri.push({ kullaniciId: this.state.personelDetail.kullanici.kullaniciId, rolId: parseInt(value) })
    }
    this.setState({ personelDetail: personelDetail })
  }



  handleCloseModal = (event) => {
    this.setState({ showDetail: false });
  }

  handleEditForm = (event) => {
    this.setState({ formItemDisabled: false });
  }

  DeletePersonel = async () => {

    var mesaj = '';
    await fetch(config.apiEndpoint + 'kullanici/' + this.state.personelDetail.kullanici.kullaniciId, {method: 'DELETE', headers:this.headers}).then(function(response) {
      return response.json();
    })
    .then(function(jsonStr) {
      mesaj = jsonStr;
    });

    this.setState({ showDetail: false, response: mesaj, showToast: true, datetime: Date.now() },
      function () {
        this.searchPersonel(true);
      }
    );
  }

  handleDeletePersonel = (event) => {
    confirmAlert({
      title: 'Dikkat',
      message: 'Personeli silmek istediğinize emin misiniz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => this.DeletePersonel()
        },
        {
          label: 'Hayır',
        }
      ]
    });
  }


  handleAddPersonel = (event) => {
    this.setState({
      formItemDisabled: false,
      showDetail: true, personelDetail: {
        kullanici: {
          ad: '',
          soyad: '',
          eposta: '',
          gorevi: '',
          departmanId: 0,
          tcKimlikNo: '',
          dogumTarihi: null,
          dogumYeri: '',
          il: '',
          ilce: '',
          iseBaslamaTarihi: null,
          istenAyrilmaTarihi: null,
          telefon: '',
          siraNumarasi: '',
          kullaniciAdi: '',
          sifre: '',
          ozelBilgi: '',
          eskiDepartman: '',
          personelMi: false,
          kanGrubu:'',
          adres: '',
        },
        kullaniciRolleri: [
        ]
      }
    });
    this.setValues();
  }


  handleFileOpen = (event) => {
    document.getElementById('photo_input').click();
  }

  handleFileUpload = (event) => {
    const personelDetailChanged = Object.assign({}, this.state.personelDetail);
    const f = document.getElementById("photo_input").files[0];

    if (f) {
      const reader = new FileReader();
      reader.onload = function (evt) {
        const contents = evt.target.result;
        personelDetailChanged.kullanici.resim = contents;
        document.getElementById("user_image").src = contents;
      };
      reader.readAsDataURL(f);
    }


    this.setState({ personelDetail: personelDetailChanged });
  }

  handleSaveForm = async (event) => {
    event.preventDefault();

    const personelDetailChanged = Object.assign({}, this.state.personelDetail);

    personelDetailChanged.kullanici.ad = event.target['ad'].value;
    personelDetailChanged.kullanici.soyad = event.target['soyad'].value;
    personelDetailChanged.kullanici.eposta = event.target['eposta'].value;
    personelDetailChanged.kullanici.gorevi = event.target['gorevi'].value;
    personelDetailChanged.kullanici.departmanId = event.target['departman'].value;
    personelDetailChanged.kullanici.tcKimlikNo = event.target['tcKimlikNo'].value;
    personelDetailChanged.kullanici.dogumTarihi = event.target['dogumTarihi'].value;
    personelDetailChanged.kullanici.dogumYeri = event.target['dogumYeri'].value;
    personelDetailChanged.kullanici.il = event.target['il'].value;
    personelDetailChanged.kullanici.ilce = event.target['ilce'].value;
    personelDetailChanged.kullanici.iseBaslamaTarihi = event.target['iseBaslamaTarihi'].value;
    personelDetailChanged.kullanici.istenAyrilmaTarihi = event.target['istenAyrilmaTarihi'].value;
    personelDetailChanged.kullanici.telefon = event.target['telefon'].value;
    personelDetailChanged.kullanici.siraNumarasi = event.target['siraNumarasi'].value;
    personelDetailChanged.kullanici.kullaniciAdi = event.target['kullaniciAdi'].value;
    personelDetailChanged.kullanici.sifre = event.target['sifre'].value;
    personelDetailChanged.kullanici.ozelBilgi = event.target['ozelBilgi'].value;
    personelDetailChanged.kullanici.eskiDepartman = event.target['eskiDepartman'].value;
    personelDetailChanged.kullanici.personelMi = event.target['personelMi'].checked;
    personelDetailChanged.kullanici.kanGrubu = event.target['kanGrubu'].value;
    personelDetailChanged.kullanici.adres = event.target['adres'].value;

    var mesaj = '';
    if (personelDetailChanged.kullanici.kullaniciId == null) {
      await fetch(config.apiEndpoint + 'kullanici/', {method: 'POST', body:JSON.stringify(personelDetailChanged), headers:this.headers}).then(function(response) {
        return response.json();
      })
      .then(function(jsonStr) {
        mesaj = jsonStr;
      });
    }
    else {
      this.setState({ personelDetail: personelDetailChanged });
      await fetch(config.apiEndpoint + 'kullanici/' + this.state.personelDetail.kullanici.kullaniciId, {method: 'PUT', body:JSON.stringify(personelDetailChanged), headers:this.headers}).then(function(response) {
        return response.json();
      })
      .then(function(jsonStr) {
        mesaj = jsonStr;
      });
    }

    this.setState({ showDetail: false, response: mesaj, showToast: true, datetime: Date.now() },
      function () {
        this.searchPersonel(true);
      });

  }

  contextMenu = (e) => {
    e.preventDefault();
  }

  render() {
    return <React.Fragment>
      <RightMenu />

      <ToastCard response={this.state.response} showToast={this.state.showToast} datetime={this.state.datetime} />
      <div className="modal fade" id="modalCenter" tabIndex="-1" aria-hidden="true">
        <input type="file" name="" id='photo_input' accept="image/png, image/gif, image/jpeg" onChange={this.handleFileUpload} hidden />
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            {this.state.showDetail ?
              <form id="formAuthenticationc" className="mb-3" onSubmit={this.handleSaveForm}>
                <div className="modal-header">
                  <h5 className="modal-title" id="modalCenterTitle">{this.state.personelDetail.kullanici['ad']} {this.state.personelDetail.kullanici['soyad']}</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <img id="user_image" alt={this.state.personelDetail.kullanici['ad'] +' '+ this.state.personelDetail.kullanici['soyad']} className="card-img-top" onClick={this.state.formItemDisabled ? null : this.handleFileOpen} src={(this.state.personelDetail.kullanici['resim'] == null ? config.blankPhoto : config.imageEndpoint + this.state.personelDetail.kullanici['resim'])} onContextMenu={this.contextMenu} />
                  </div>
                  <div className="row g-2">
                    <div className="col mb-0 form-floating">
                      <input
                        type="text"
                        id="ad"
                        name="ad"
                        className="form-control"
                        placeholder="Ad"
                        key={this.state.personelDetail.kullanici['ad']}
                        defaultValue={this.state.personelDetail.kullanici['ad']}
                        disabled={this.state.formItemDisabled}
                      />
                      <label htmlFor="ad" className="form-label">Ad</label>
                    </div>
                    <div className="col mb-0 form-floating">

                      <input
                        type="text"
                        id="soyad"
                        name="soyad"
                        className="form-control"
                        placeholder="Soyad"
                        key={this.state.personelDetail.kullanici['soyad']}
                        defaultValue={this.state.personelDetail.kullanici['soyad']}
                        disabled={this.state.formItemDisabled}
                      />
                      <label htmlFor="soyad" className="form-label">Soyad</label>
                    </div>
                  </div>

                  <div className="row g-2">
                    <div className="col mb-0 form-floating">

                      <input
                        type="text"
                        id="gorevi"
                        name="gorevi"
                        className="form-control"
                        placeholder="Görevi"
                        key={this.state.personelDetail.kullanici['gorevi']}
                        defaultValue={this.state.personelDetail.kullanici['gorevi']}
                        disabled={this.state.formItemDisabled}
                      />
                      <label htmlFor="gorevi" className="form-label">Görevi</label>
                    </div>
                    <div className="col mb-0 form-floating">
                      <select className="form-select" id="departman" disabled={this.state.formItemDisabled} value={this.state.kullaniciDepartmant} onChange={this.handlekullaniciDepartmantChanged}>
                        {this.state.departmanlar.map(tag => <option key={tag['adi']} value={tag['departmanId']}>{tag["adi"]}</option>)}
                      </select>
                      <label htmlFor="departman" className="form-label">Albüm - Departman</label>
                    </div>
                  </div>

                  <div className="row g-2">
                    {this.state.personelDetail.kullanici.hasOwnProperty('tcKimlikNo') ?
                      <div className="col mb-0 form-floating">

                        <input
                          type="text"
                          id="tcKimlikNo"
                          name="tcKimlikNo"
                          className="form-control"
                          placeholder="TC Kimlik No"
                          key={this.state.personelDetail.kullanici['tcKimlikNo']}
                          defaultValue={this.state.personelDetail.kullanici['tcKimlikNo']}
                          disabled={this.state.formItemDisabled}
                        />
                        <label htmlFor="tcKimlikNo" className="form-label">TC Kimlik No</label>
                      </div>
                      : null
                    }
                    {this.state.personelDetail.kullanici.hasOwnProperty('eposta') ?
                      <div className="col mb-0 form-floating">

                        <input
                          type="text"
                          id="eposta"
                          name="eposta"
                          className="form-control"
                          placeholder="Eposta"
                          key={this.state.personelDetail.kullanici['eposta']}
                          defaultValue={this.state.personelDetail.kullanici['eposta']}
                          disabled={this.state.formItemDisabled}
                        />
                        <label htmlFor="eposta" className="form-label">Eposta</label>
                      </div> : null
                    }
                  </div>

                  <div className="row g-2">
                    {this.state.personelDetail.kullanici.hasOwnProperty('dogumTarihi') ?
                      <div className="col mb-0 form-floating">
                        <input
                          type="date"
                          id="dogumTarihi"
                          name="dogumTarihi"
                          className="form-control"
                          placeholder="TC Kimlik No"
                          key={this.state.personelDetail.kullanici['dogumTarihi']}
                          defaultValue={this.state.personelDetail.kullanici['dogumTarihi'] === null ? null : new Date(this.state.personelDetail.kullanici['dogumTarihi']).getFullYear() + '-' + ("0" + (new Date(this.state.personelDetail.kullanici['dogumTarihi']).getMonth() + 1)).slice(-2) + '-' + ('0' + new Date(this.state.personelDetail.kullanici['dogumTarihi']).getDate()).slice(-2)}
                          disabled={this.state.formItemDisabled}
                        />
                        <label htmlFor="dogumTarihi" className="form-label">Doğum Tarihi</label>
                      </div>
                      : null
                    }
                    {this.state.personelDetail.kullanici.hasOwnProperty('dogumYeri') ?
                      <div className="col mb-0 form-floating">

                        <input
                          type="text"
                          id="dogumYeri"
                          name="dogumYeri"
                          className="form-control"
                          placeholder="Doğum Yeri"
                          key={this.state.personelDetail.kullanici['dogumYeri']}
                          defaultValue={this.state.personelDetail.kullanici['dogumYeri']}
                          disabled={this.state.formItemDisabled}
                        />
                        <label htmlFor="dogumYeri" className="form-label">Doğum Yeri</label>
                      </div> : null
                    }
                  </div>

                  <div className="row g-2">
                    {this.state.personelDetail.kullanici.hasOwnProperty('il') ?
                      <div className="col mb-0 form-floating">

                        <input
                          type="text"
                          id="il"
                          name="il"
                          className="form-control"
                          placeholder="İl"
                          key={this.state.personelDetail.kullanici['il']}
                          defaultValue={this.state.personelDetail.kullanici['il']}
                          disabled={this.state.formItemDisabled}
                        />
                        <label htmlFor="il" className="form-label">İl</label>
                      </div> : null
                    }
                    {this.state.personelDetail.kullanici.hasOwnProperty('ilce') ?
                      <div className="col mb-0 form-floating">

                        <input
                          type="text"
                          id="ilce"
                          name="ilce"
                          className="form-control"
                          placeholder="İlçe"
                          key={this.state.personelDetail.kullanici['ilce']}
                          defaultValue={this.state.personelDetail.kullanici['ilce']}
                          disabled={this.state.formItemDisabled}
                        />
                        <label htmlFor="ilce" className="form-label">İlçe</label>
                      </div> : null
                    }
                  </div>

                  <div className="row g-2">
                    {this.state.personelDetail.kullanici.hasOwnProperty('iseBaslamaTarihi') ?
                      <div className="col mb-0 form-floating">
                        <input
                          type="date"
                          id="iseBaslamaTarihi"
                          name="iseBaslamaTarihi"
                          className="form-control"
                          placeholder="İşe Başlama T."
                          key={this.state.personelDetail.kullanici['iseBaslamaTarihi']}
                          defaultValue={this.state.personelDetail.kullanici['iseBaslamaTarihi'] === null ? null : new Date(this.state.personelDetail.kullanici['iseBaslamaTarihi']).getFullYear() + '-' + ("0" + (new Date(this.state.personelDetail.kullanici['iseBaslamaTarihi']).getMonth() + 1)).slice(-2) + '-' + ('0' + new Date(this.state.personelDetail.kullanici['iseBaslamaTarihi']).getDate()).slice(-2)}
                          disabled={this.state.formItemDisabled}
                        />
                        <label htmlFor="iseBaslamaTarihi" className="form-label">İşe Başlama T.</label>
                      </div>
                      : null
                    }
                    {this.state.personelDetail.kullanici.hasOwnProperty('istenAyrilmaTarihi') ?
                      <div className="col mb-0 form-floating">
                        <input
                          type="date"
                          id="istenAyrilmaTarihi"
                          name="istenAyrilmaTarihi"
                          className="form-control"
                          placeholder="İşten Ayrılma T."
                          key={this.state.personelDetail.kullanici['istenAyrilmaTarihi']}
                          defaultValue={this.state.personelDetail.kullanici['istenAyrilmaTarihi'] === null ? null : new Date(this.state.personelDetail.kullanici['istenAyrilmaTarihi']).getFullYear() + '-' + ("0" + (new Date(this.state.personelDetail.kullanici['istenAyrilmaTarihi']).getMonth() + 1)).slice(-2) + '-' + ('0' + new Date(this.state.personelDetail.kullanici['istenAyrilmaTarihi']).getDate()).slice(-2)}
                          disabled={this.state.formItemDisabled}
                        />
                        <label htmlFor="istenAyrilmaTarihi" className="form-label">İşten Ayrılma T.</label>
                      </div>
                      : null
                    }
                  </div>

                  <div className="row g-2">
                    {this.state.personelDetail.kullanici.hasOwnProperty('telefon') ?
                      <div className="col mb-0 form-floating">

                        <input
                          type="tel"
                          id="telefon"
                          name="telefon"
                          className="form-control"
                          placeholder="Telefon"
                          key={this.state.personelDetail.kullanici['telefon']}
                          defaultValue={this.state.personelDetail.kullanici['telefon']}
                          disabled={this.state.formItemDisabled}
                        />
                        <label htmlFor="telefon" className="form-label">Telefon</label>
                      </div> : null
                    }
                    {this.state.personelDetail.kullanici.hasOwnProperty('siraNumarasi') ?
                      <div className="col mb-0 form-floating">

                        <input
                          type="number"
                          id="siraNumarasi"
                          name="siraNumarasi"
                          className="form-control"
                          placeholder="Sıra Numarası"
                          key={this.state.personelDetail.kullanici['siraNumarasi']}
                          defaultValue={this.state.personelDetail.kullanici['siraNumarasi']}
                          disabled={this.state.formItemDisabled}
                        />
                        <label htmlFor="siraNumarasi" className="form-label">Sıra Numarası</label>
                      </div> : null
                    }
                  </div>

                  <div className="row g-2">
                    {this.state.personelDetail.kullanici.hasOwnProperty('ozelBilgi') ?
                      <div className="col mb-0 form-floating">

                        <input
                          type="text"
                          id="ozelBilgi"
                          name="ozelBilgi"
                          className="form-control"
                          placeholder="Özel"
                          key={this.state.personelDetail.kullanici['ozelBilgi']}
                          defaultValue={this.state.personelDetail.kullanici['ozelBilgi']}
                          disabled={this.state.formItemDisabled}
                        />
                        <label htmlFor="ozelBilgi" className="form-label">Özel</label>
                      </div> : null
                    }
                    {this.state.personelDetail.kullanici.hasOwnProperty('eskiDepartman') ?
                      <div className="col mb-0 form-floating">

                        <input
                          type="text"
                          id="eskiDepartman"
                          name="eskiDepartman"
                          className="form-control"
                          placeholder="Eski Departman"
                          key={this.state.personelDetail.kullanici['eskiDepartman']}
                          defaultValue={this.state.personelDetail.kullanici['eskiDepartman']}
                          disabled={this.state.formItemDisabled}
                        />
                        <label htmlFor="eskiDepartman" className="form-label">Eski Departman</label>
                      </div> : null
                    }
                  </div>

                  <div className="row g-2">
                    {this.state.personelDetail.kullanici.hasOwnProperty('kullaniciAdi') ?
                      <div className="col mb-0 form-floating">

                        <input
                          type="text"
                          id="kullaniciAdi"
                          name="kullaniciAdi"
                          className="form-control"
                          placeholder="Kullanıcı Adı"
                          key={this.state.personelDetail.kullanici['kullaniciAdi']}
                          defaultValue={this.state.personelDetail.kullanici['kullaniciAdi']}
                          disabled={this.state.formItemDisabled}
                        />
                        <label htmlFor="kullaniciAdi" className="form-label">Kullanıcı Adı</label>
                      </div> : null
                    }
                    {this.state.personelDetail.kullanici.hasOwnProperty('sifre') ?
                      <div className="col mb-0 form-floating">

                        <input
                          type="text"
                          id="sifre"
                          name="sifre"
                          className="form-control"
                          placeholder="Şifre"
                          key={this.state.personelDetail.kullanici['sifre']}
                          defaultValue={this.state.personelDetail.kullanici['sifre']}
                          disabled={this.state.formItemDisabled}
                        />
                        <label htmlFor="sifre" className="form-label">Şifre</label>
                      </div> : null
                    }
                  </div>

                  <div className="row g-2">
                    {this.state.personelDetail.kullanici.hasOwnProperty('kanGrubu') ?
                      <div className="col mb-0 form-floating">
                      <select className="form-select" id="kanGrubu" disabled={this.state.formItemDisabled} value={this.state.kullanicikanGrubu} onChange={this.handlekullanicikanGrubuChanged}>
                        <option key="Seçiniz" value="">Seçiniz</option>
                        {config.bloodTypes.map(tag => <option key={tag['adi']} value={tag['deger']}>{tag["adi"]}</option>)}
                      </select>
                      <label htmlFor="kanGrubu" className="form-label">Kan Grubu</label>
                    </div> : null
                    }
                  </div>

                  <div className="row">
                    {this.state.personelDetail.kullanici.hasOwnProperty('adres') ?
                      <div className="col mb-0 form-floating textarea">
                      <textarea
                          type="text"
                          id="adres"
                          name="adres"
                          className="form-control"
                          placeholder="Adres"
                          rows="4"
                          key={this.state.personelDetail.kullanici['adres']}
                          defaultValue={this.state.personelDetail.kullanici['adres']}
                          disabled={this.state.formItemDisabled}
                        />
                      <label htmlFor="adres" className="form-label">Adres</label>
                    </div> : null
                    }
                  </div>


                  {(this.state.personelDetail.kullanici.hasOwnProperty('personelMi') && this.state.userHasEditPermission)?
                    <div className="row">
                      <div className="col-md">
                        {<div className="form-check form-check-inline mt-3">
                          <input className="form-check-input" type="checkbox" id="personelMi" value="personelMi" defaultChecked={this.state.personelDetail.kullanici['personelMi']} disabled={this.state.formItemDisabled} />
                          <label className="form-check-label" htmlFor="personelMi">Personel</label>
                        </div>}

                      </div>
                    </div> : null
                  }

                  {this.state.personelDetail.kullanici.hasOwnProperty('kullaniciAdi') ?
                    <div className="row mt20">
                      <p className="form-label">Roller</p>
                      <div className="col-md">
                        {this.state.roller.map(tag => <div className="form-check form-check-inline mt-3" key={String(this.state.personelDetail.kullaniciRolleri.findIndex((kullaniciRolu) => kullaniciRolu.rolId === tag['rolId']) >= 0) + tag['rolId']}>
                          <input className="form-check-input" type="checkbox" id={'inlineCheckbox' + tag['rolId']} value={tag['rolId']} onChange={this.handleRolSelected} defaultChecked={this.state.personelDetail.kullaniciRolleri.findIndex((kullaniciRolu) => kullaniciRolu.rolId === tag['rolId']) >= 0} disabled={this.state.formItemDisabled} />
                          <label className="form-check-label" htmlFor={'inlineCheckbox' + tag['rolId']}>{tag['adi']}</label>
                        </div>)}

                      </div>
                    </div> : null
                  }

                </div>

                <div className="modal-footer">
                  {(this.state.formItemDisabled && this.state.userHasEditPermission) ? <React.Fragment><button type="button" className="btn btn-outline-primary" onClick={this.handleEditForm}>Düzenle</button> <button type="button" className="btn btn-outline-danger" onClick={this.handleDeletePersonel}>Sil</button></React.Fragment> : null}
                  {this.state.formItemDisabled ? null : <button type="submit" className="btn btn-primary">Kaydet</button>}
                </div>
              </form> : null}
          </div>
        </div>
      </div>
      {this.state.showPersonels ? <nav
        className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
        id="layout-navbar"
      >
        <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
          <div className="navbar-nav align-items-center">
            <div className="input-group">
              <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" id="selected-album">{ALBUM_SECINIZ}</button>
              <ul className="dropdown-menu">
                <li key={ALBUM_SECINIZ}><a className="dropdown-item" href="#" value={ALBUM_SECINIZ} onClick={this.handleSearchDepartmanChange}>{ALBUM_SECINIZ}</a></li>
                {this.state.departmanlar.map(tag => <li key={tag['adi']}><a className="dropdown-item" href="#" value={tag['adi']} onClick={this.handleSearchDepartmanChange}>{tag["adi"]}</a></li>)}
                <li key={AYRILANLAR}><a className="dropdown-item" href="#" value={AYRILANLAR} onClick={this.handleSearchDepartmanChange}>{AYRILANLAR}</a></li>
              </ul>
              <div id="search-area" className="nav-item d-flex align-items-center">
                <i className="bx bx-search fs-4 lh-0"></i>
                <input
                  id="search-input"
                  type="text"
                  className="form-control border-0 shadow-none"
                  placeholder="Personel Ara..."
                  aria-label="Personel Ara..."
                  onChange={this.searchPersonel}
                />
              </div>
              {this.state.userHasEditPermission ?
              <Fragment>
              <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" id="selected-kan">{KAN_GRUBU}</button>
              <ul className="dropdown-menu right">
                <li key={KAN_GRUBU}><a className="dropdown-item" href="#" value={KAN_GRUBU} onClick={this.handleSearchKanChange}>{KAN_GRUBU}</a></li>
                {config.bloodTypes.map(tag => <li key={tag['adi']}><a className="dropdown-item" href="#" value={tag['adi']} onClick={this.handleSearchKanChange}>{tag["adi"]}</a></li>)}
              </ul></Fragment>
               : null}
            </div>
          </div>
        </div>
      </nav> : null }

      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
        {this.state.showPersonels ? <div id="kvkk" className="alert alert-secondary alert-dismissible" role="alert">
            Altınkaynak Şirket Albümü Şirket merkez ve şubelerinin farklı şehir ve lokasyonlarda bulunması personelin birbirini tanımalarını zorlaştırabilmektedir. Şirket içinde iletişim kopukluğu ve güvenlik zafiyeti oluşmasını önlemek için “Altınkaynak Şirket Albümü” oluşturulmuştur. Tanınırlık sağlamak üzere albüm, merkez/şubelerde çalışan ve ayrılan personeli kapsamaktadır. Albümde bulunan fotoğrafların kopyalanması, indirilmesi veya ekran görüntüsünün alınması 6698 sayılı kişisel verilerin korunması kanunu kapsamında yazılı izne tabidir
          </div> : null }
          <h4 className="fw-bold py-2 mb-1"><span id="selected-album-text">{this.state.departmanText}</span>
            
          {(this.state.showPersonels && this.state.showWorkingPeoplesButton) ?<button type="button" className="btn btn-sm ml10" onClick={this.handleWorkingType}>
              {this.state.showWorkingPeoples ? 'Ayrılanlar' : 'Çalışanlar'}
            </button> : null}

            {this.state.userHasEditPermission ?
              <Fragment>
                <button type="button" className="btn btn-outline-primary thin ml20" onClick={this.handleAddPersonel} data-bs-toggle="modal"
                  data-bs-target="#modalCenter">
                  <i className="tf-icons bx bx-user"></i>
                  Ekle
                </button>
               </Fragment> : null}
          </h4>

          <div className={this.state.buttonsClass}>
          <button type="button" className="btn btn-outline-primary thin hidden" onClick={this.handleMainPage}
              id="mainpage-button">
              <i className="tf-icons bx bx-chevron-left"></i>
              Anasayfa
            </button>

            <button type="button" id="table-button" className="btn btn-outline-primary thin right" onClick={this.handleDisplayType}>
              <i className={this.state.isCard ? 'tf-icons bx bx-list-ul' : 'tf-icons bx bx-id-card'}></i>
              {this.state.isCard ? ' Tablo Görünümü' : ' Kart Görünümü'}
            </button>
          </div>

            {this.state.isCard ?
             <div className="row row-cols-1 row-cols-md-3 g-4 mb-5">
              {this.state.arananPersoneller.map(tag => <PersonelCard key={tag.kullanici['kullaniciId']} parentCallback={this.handleOpenModal} values={tag} />)}
              </div>:
              <PerfectScrollbar>
              <table className="table">
                <thead>
                  <tr>
                    <th>Resim</th>
                    <th>Ad Soyad</th>
                    {this.state.arananPersoneller.length > 0 && this.state.arananPersoneller[0].kullanici.hasOwnProperty('tcKimlikNo') ?
                      <React.Fragment>
                        <th>T.C. Kimlik</th>
                        <th>Telefon</th>
                        <th>D. Tarihi</th>
                        <th>D. Yeri</th>
                        <th>İli</th>
                        <th>İlçesi</th>
                        <th>İş.Baş.Tar.</th>
                        <th>İş.Ayrıl.Tar</th>
                      </React.Fragment>
                      : null}
                    <th>Görevi</th>
                    <th>Albüm - Departman</th>
                    <th>Detay</th>
                  </tr>
                </thead>
                <tbody className="table-border-bottom-0">
                  {this.state.arananPersoneller.map(tag => <PersonelListCard key={tag.kullanici['kullaniciId']} parentCallback={this.handleOpenModal} values={tag} isAllFields={tag.kullanici.hasOwnProperty('tcKimlikNo')} />)}
                </tbody>
              </table></PerfectScrollbar>
            }

        </div>

        <footer className="content-footer footer bg-footer-theme">
          <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
            <div className="mb-2 mb-md-0">
              © Altınkaynak
            </div>
          </div>
        </footer>

        <div className="content-backdrop fade"></div>
      </div>
      <div className="layout-overlay layout-menu-toggle"></div>

    </React.Fragment>;
  }
}

export default AlbumSayfa;