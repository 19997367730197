import React, { Component } from 'react';
import config from "../config.json";

class GirisSayfa extends Component {


    state = {
        message: ''
    }

    componentDidMount() {
        document.title = "Altınkaynak Personel Sistemi - Giriş"

        if (localStorage) {
            localStorage.removeItem("user");
        }
    }

    componentWillUnmount() {
    }


    handleSubmit = async (event) => {
        event.preventDefault();
        var page = this;
        var querystring = require('qs');


        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: querystring.stringify({
                KullaniciAdi: event.target[0].value,
                Sifre: event.target[1].value,
            })
        };
        fetch(config.apiEndpoint + 'Auth', requestOptions)
            .then(response => response.json())
            .then(data => {
                    if (data["access_token"] !== '') {
                        data["expired_at"] = (new Date().getTime() / 1000) + data["expires_in"]
                        localStorage.setItem('user', JSON.stringify(data));
                        window.location = '/Album'
                    }
                    else {
                        page.setState({ message: data["message"] });
                    }
                }
            );
    };

    render() {
        return <React.Fragment>
            <div className="container-xxl">
                <div className="authentication-wrapper authentication-basic container-p-y">
                    <div className="authentication-inner">
                        <div className="card">
                            <div className="card-body">
                                <div className="app-brand justify-content-center">
                                    <h1 className="logo"><a href="/">Altınkaynak</a></h1>
                                </div>
                                <h4 className="mb-2">Altınkaynak Personel Sistemi</h4>
                                <p className="mb-4">Lütfen kullanıcı adınız ve şifrenizle giriş yapınız... </p>
                                <p className="alert-danger">{this.state.message}</p>
                                <form id="formAuthentication" className="mb-3" onSubmit={this.handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="username" className="form-label">KULLANICI ADI</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="username"
                                            name="username"
                                            placeholder="Kullanıcı adınızı girin"
                                            autoFocus
                                        />
                                    </div>
                                    <div className="mb-3 form-password-toggle">
                                        <div className="d-flex justify-content-between">
                                            <label className="form-label" htmlFor="password">ŞİFRE</label>
                                        </div>
                                        <div className="input-group input-group-merge">
                                            <input
                                                type="password"
                                                id="password"
                                                className="form-control"
                                                name="password"
                                                placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                                aria-describedby="password"
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <button className="btn btn-primary d-grid w-100" type='submit'>Giriş</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>;
    }
}

export default GirisSayfa;